<template>
    <span class="mr-3">
        <v-icon title="Click to copy" @click.stop.prevent="copyTestingCode">{{clipIcon}}</v-icon>
     </span>
</template>
<script>
  export default {
    name: "clipboard",
    props: ["value"],
    data:function(){
      return {
         clipIcon: 'mdi-clipboard-outline'
      };
    },
    methods: {
      copyTestingCode () {
        navigator.clipboard.writeText(this.value).then(this.copied(), function() {
          console.log('Not Copied')
        });
      },
      copied(){
        this.$set(this, "clipIcon", "mdi-clipboard-check-outline");
      }
    },
  }
</script>
<template>
<div>
    <span class="d-inline-flex">
        <template v-if="row.n_active!=''">
        <div @click.stop="dialog = true">
       <v-menu open-on-hover top offset-y min-width="350">
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">
            <div class="d-flex">
              <div class="mr-2">
                <v-icon
                        :color="userIconColor"
                        class="cursorPointer"
                >mdi-account
            </v-icon>
            <template v-if="row.n_newUser24hr=='yes'">
                <span class="newUserBadge cursorPointer">N</span>
            </template>
              </div>
            </div>
          </a>
        </template>
        <v-list flat dense >
          <div class="subtitle-1 primary white--text">
              <span class="ml-2">User Status</span>
          </div>
          <div class="caption ml-5">
          <div v-html="userTitle"></div>
          </div>
        </v-list>
      </v-menu>
     </div>
    <v-dialog
        v-model="dialog"
        max-width="350"
    >
        <v-card>
            <v-card-title class="headline-box-headline">
                {{language.componentLabel.labelDW}} {{actionLabel}}?
            </v-card-title>
            <v-card-text>
                 {{language.componentLabel.labelClickYes}} {{actionLabel}} {{language.componentLabel.labelAccount}}
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
            >{{language.componentLabel.labelNo}}
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="updateUserLife(row.n_active,row.c_owner)"
            >{{language.componentLabel.labelYes}}
            </v-btn>
            </v-card-actions>
            </v-card>
    </v-dialog>
    </template>
    </span>
    <span>
    <template v-if="row.c_adminNote!='' && row.c_adminNote!=null">
        <v-icon :title="adminNote">mdi-note</v-icon>
    </template>
    <template v-else>
        <v-icon title="No Admin Notes">mdi-note-outline</v-icon>
    </template>
    <template v-if="row.n_userOnline==1">
        <v-icon color="green" title="Online">mdi-circle</v-icon>
    </template>
    <template v-else>
        <v-icon title="Offline">mdi-circle</v-icon>
    </template>
    <template>
        <span class="ml-1">
             <v-dialog
                     v-model="dialogProject"
                     scrollable
             >
      <template v-slot:activator="{ on, attrs }">
          <a   v-bind="attrs"
               v-on="on"
               title="Project Details"
          ><v-icon>mdi-card-bulleted-settings-outline</v-icon>
            </a>
      </template>
      <v-card>
        <v-card-title>{{row.c_firstname}}  {{row.c_lastname}} ({{row.c_email}}) Projects Details</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
         <v-list
                 dense
           >
   <v-card color="primary lighten-2" elevation="1" >
      <v-card-title>
       <v-subheader class="subtitle-2 white--text">Total Projects ({{projectCount}})</v-subheader>
      </v-card-title>
      <v-data-table
              dense
              :headers="headers"
              :items=filteredData
              class="elevation-1"
              fixed-header
              group-by="orgName"
              :items-per-page="-1"
              item-key="c_domain"
              :show-expand="false"
      >
        <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length" @click="toggle" bgcolor="#E3F2FD" style="cursor: pointer;" >
                <v-btn small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                Org Name: {{ group }}
            </td>
        </template><template v-slot:item="{ item }">
        <tr>
          <td>{{ item.c_name }}</td>
          <td>{{ item.c_domain }}</td>
          <td>{{ item.userHash }}</td>
          <td>{{ item.c_organisation }}</td>
        </tr>
      </template>
      </v-data-table>
    </v-card>
         </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
                 <primaryButton
                        label="Close"
                        icon="mdi-close"
                        @click="dialogProject = false"
                        type="button"
                        color="primary"
                ></primaryButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </span>
    </template>
    <template v-if="row.c_invitedBy!='' && row.c_invitedBy!=null">
       <span class="ml-1">
        <v-menu open-on-hover top offset-y min-width="350">
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">
                 <v-icon>mdi-briefcase-plus</v-icon>
          </a>
        </template>
        <v-list flat dense >
          <div class="subtitle-1 primary white--text">
              <span class="ml-2">User Invited Info</span>
          </div>
          <div class="caption ml-5">
          <div style="max-width: 500px;" v-html="invitedBy"></div>
          </div>
        </v-list>
      </v-menu>
       </span>
    </template>
    <template v-if="row.c_registerCode!='' && row.c_registerCode!=null">
       <span class="ml-1">
        <v-menu :close-on-click="true" open-on-hover top offset-y min-width="350">
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">
                 <v-icon color="blue">mdi-code-array</v-icon>
          </a>
        </template>
        <v-list flat dense >
          <div class="subtitle-1 primary white--text">
              <span class="ml-2">Used Register Code</span>
          </div>
          <div class="caption ml-5">
           <span>
            <clipboard :value="usedCode"></clipboard><span class="mt-1" style="position: absolute;" v-html="usedCode">
           </span>
           </span>
          </div>
        </v-list>
      </v-menu>
       </span>
    </template>
    </span>
</div>
</template>
<script>
  import primaryButton from "@/commonComponents/primaryButton.vue";
  import clipboard from "@/commonComponents/clipboard.vue";
  export default {
    data:function(){
          return {
            searchMe: '',
        items: [],
        dialog: false,
        dialogProject: false,
        headers: [
          { text: 'Project Name', value: 'c_name' ,width: "300px"  },
          { text: 'Project hash', value: 'c_domain' },
          { text: 'User hash', value: 'userHash'  },
          { text: 'Org hash', value: 'c_organisation'  },
        ],

      };
    },
    components: {  primaryButton,  clipboard, },

    /*   created () {
         this.closeAll();
       },*/
    computed:{
      projectCount(){
        let projectCount=0;
        if(typeof this.row.userInfoDetails!='undefined' && this.row.userInfoDetails!=''){
          projectCount = this.row.userInfoDetails.length;
        }
        return projectCount;
      },
      filteredData(){
        let dataVal = [];
        if(typeof this.row.userInfoDetails!='undefined' && this.row.userInfoDetails!=''){
          dataVal = this.row.userInfoDetails;
          dataVal.map(o => (o.userHash = this.row.c_owner));
        }
      /*  dataVal.forEach(function(v){ //remove two val
          delete v.n_type;
        });*/
        return dataVal;
      },
      invitedBy(){
        let title="";
        if(this.row.c_invitedBy!='' && this.row.c_invitedBy!=null){
          title="Added by Project Admin/Manager <br>"+this.row.c_invitedBy;
        }
        return title;
      },
      usedCode(){
        let usedCode="";
        if(this.row.c_registerCode!='' && this.row.c_registerCode!=null){
          usedCode= this.row.c_registerCode;
        }
        return usedCode;
      },
      adminNote() {
        let note = "";
        if(this.row.c_adminNote!='' && this.row.c_adminNote!=null){
          note = this.row.c_adminNote;
        }
       return note;
      },
          actionLabel(){
        let title="Activate";
        if(this.row.n_active=="1"){
          title= "Deactivate";
        }
        return title;
      },
      userTitle(){
        let title="";
        if(typeof this.row.n_super!="undefined"){
          if(this.row.n_super==1){
            title+="<li>Super User</li>";
          }else{
            title+="<li>Normal User</li>";
          }
          if(this.row.n_newUser24hr=='yes'){
            title += "<li>New User <= 24 hours</li>";
          }
          if(this.row.n_timeDiff!=null){
            title+=" <li>Joined:Less than 1 week</li> ";
          }
        }
        if(this.row.n_active=='2'){
          title = "<li style='color:red;'>Deactivated User!</li>";
        }

        if(this.row.n_super==0 && this.row.n_dateRegAck=="0" && this.row.n_active!='2'){
          title+=" <li style='color:red;'>Not yet acknowledged</li>";
        }

        title+="<span class='ml-n5 indigo--text'>Click the icon to activate/deactivate the user.</span>";
          return "<ul>"+title+"<ul>";
      },

      userIconColor(){
        let code="#5C9CCC";
        if(this.row.n_super==0){
            code="green";
        }
        if(this.row.n_super==0 && this.row.n_dateRegAck=="0"){
          code="black";
        }
        if(this.row.n_active=='2' || this.row.n_active=='3'){
          code="red";
        }
        return code;
      }
    },
    methods: {
      copyTestingCode () {
        console.log( this.$refs.clone);
        console.log(document.querySelector('.rCode'));

          var copyTextarea = document.querySelector('.rCode');
          copyTextarea.focus();
          copyTextarea.select();

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
          } catch (err) {
            console.log('Oops, unable to copy');
          }

      },
        closeAll () {
          Object.keys(this.$refs).forEach(k => {
            console.log(this.$refs[k]);
            if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
              this.$refs[k].$el.click();
            }
          });
        },
      updateUserLife(active,nid){
        let action='1';
        if(active=='1'){
          action='2';
        }
       let caller = this;
        this.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=administration&f2=superUserAdministration',
          dataType: 'json',
          data: {
            function: 'updateUserLife',
            action: action,
            key: nid,
            requestType: 'ajax',
          },
        })
            .then(response => {
            caller.updateTable(response.data.result.table);
            this.dialog=false;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    props: ["row","currentTable","result"]
  }
</script>
<style>
    .newUserBadge {
        background-color: #fa3e3e;
        color: white;
        padding: 3px;
        font-size: 7px;
        position: relative;
        bottom: 8px;
        right: 7px;
    }
    .cursorPointer{
        cursor: pointer;
    }
</style>